import Service from "../Service"

const resource = "FrzPackingCreateBins/"

export default { 

    save(obj, requestID){
        return Service.post(resource + "save", obj,{
            params: {requestID: requestID},
        });
    },
    list(dtr, requestID){
        return Service.post(resource + "list", dtr,{
            params:{requestID: requestID},
        });
    },
    saveQr(obj, requestID){
        return Service.post(resource + "saveQr", obj,{
            params: {requestID: requestID},
        });
    },
    paginadoAssembleBins(obj, requestID){
        return Service.post(resource + "paginadoAssembleBins", obj,{
            params: {requestID: requestID},
        });
    },
    DeleteCar(obj, requestID){
        return Service.post(resource + "DeleteCar", obj,{
            params: {requestID: requestID},
        });
    },
    sendBins(obj, requestID){
        return Service.post(resource + "sendBins", obj,{
            params: {requestID: requestID},
        });
    },
    UpdBins(obj, requestID){
        return Service.post(resource + "UpdBins", obj,{
            params: {requestID: requestID},
        });
    },

}