<template>
    <div>
        <v-card>
            <s-toolbar label="Armar Bins Estatico"
                dark 
                close 
                @close="closeAssembleBins()"
                color="primary"
            >
            </s-toolbar>
            <v-card dense>
            <br>
                <v-row justify="center" style="margin:auto">
                    <v-col align="center" cols="6" lg="6" md="6" hidden>
                        <s-text  v-model="PcbID" > </s-text>
                    </v-col>
                    <v-col
                        align="center"
                        cols="6"
                        lg="6"
                        md="6"
                    >   
                        <s-scanner-qr
                            :config="configScann"
                            return-object 
                            @onValue="onValue($event)"
                            :readonly="false"
                            :autofocus="false"
                            :clearInput="clearInput"
                            >
                        </s-scanner-qr>
                    </v-col>
                </v-row>
                
                <v-card-text>
                    <v-data-table
                       
                        dense
                        :headers="headerBinsAssemble"
                        :items="ListBinsAssemble"
                        class="elevation-1">


                        <template v-slot:item.Delete="{ item }">
							<v-btn
								x-small
								fab
								style="margin: 6px 6px 6px 6px; background-color: #dd3b62; color: white;"
								@click="deleteCarrito(item)">
								<v-icon
                                    style="font-size: 16px !important">
                                    fas fa-times
                                </v-icon>
							</v-btn>
						</template>
                    </v-data-table>
                </v-card-text>
                    
            </v-card>
        </v-card>
    
    
    </div>
    
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';

    import _sFrzPackingAssembleBinsServices from "@/services/FrozenProduction/FrzPackingCreateBins.js";
   
    

    export default{
        props:{
            PcbID:{
                required:true,
                type:Number,
            }
           
        },
        components: { sScannerQr },
        data:() => ({

            ListBinsAssemble:[],
            headerBinsAssemble: [
                { text:"item", value :"FasID"},
                { text: "Tunel", value: "FpcDescription"},
                { text: "Nombre carrito", value: "FasDescription"},
                { text: "N° de mallas", value: "NumberBags"},
                { text: "Peso promedio malla", value: "WeightAverageBags"},
                { text: "Peso neto malla", value: "WeightNetBags"},
                { text: "Cultivo", value: "TypeCultiveName"},
                { text: "Cliente", value: "CumNombre"},
                { text: "N° carrito", value: "FasCarNumber"},
                { text: "Accion", value: "Delete", width: "70" },
            ],   


            openModalIncomeTunnel :false,
            clearInput:false,
            configScann: {}, 
           
        }),
        
        
        methods:{
            
            closeAssembleBins(){
                this.$emit("closeAssembleBins");
            },
            onValue(val)
            {
                console.log('val',val);
                if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {

                    console.log('a guardar',val)
                    let item = {};
                
                    item.FasID = val;
                    item.PcbID = this.PcbID;
                    item.UsrCreateID = this.$fun.getUserID();
                    item.UsrUpdateID = this.$fun.getUserID();
                    
                    console.log("item", item);

                    // return
                    _sFrzPackingAssembleBinsServices.saveQr(item, this.$fun.getUserID())
                        .then( r => {
                            if(r.status == 200)
                            {
                                
                                this.$fun.alert("Pallet ingresado correctamente", "success");
                                this.PaginadoAssembleBins();
                            }
                        });
                    }

                
            },
            PaginadoAssembleBins(){
                console.log('Lista de carritos por bins',this.PcbID)
                _sFrzPackingAssembleBinsServices.paginadoAssembleBins({PcbID:this.PcbID}, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200) {
                        this.ListBinsAssemble = resp.data;
                        console.log('Lista de carritos por bins',this.ListBinsAssemble)
                    }
                });
            },
            deleteCarrito(item){
                console.log('Elimnar',item);
                let val = {
                    PabID : item.PabID,
                    UsrUpdateID : this.$fun.getUserID()
                }

                this.$fun.alert("Esta de seguro de eliminar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzPackingAssembleBinsServices
                        .DeleteCar(val, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Carrito eliminado correctamente", "success");
                                this.PaginadoAssembleBins();
                            }
                        });
                    }
                });


            },
        },
        created()
        {
            this.PaginadoAssembleBins();
        }
    }
</script>