<template>
    <div class="col-md-12 col-sm-12">
       
        <v-card class="pa-md-6 mx-lg-auto">
            <v-row>
                <v-col>
                     <h3>Creacion de Bines Estatico</h3>
                </v-col>
            </v-row>
            <v-row justify="space-around" no-gutters>
                
                <v-col cols="3">
                    <s-select                    
                        label="Turno de Produccion"
                        :items="itemTurn"
                        item-value="TrnID"
                        item-text="TnrObservation"
                        v-model="itemHead.TrnID"
                        clearable
                    >
                    </s-select>
                </v-col>
                <v-col cols="auto ">
                    <v-btn 
                        small
                        fab
                        elevation="3"
                        color="info" @click="save()">
                        <v-icon style="font-size: 16px !important;"
							>fa-thin fa-save</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="pa-md-6 mx-lg-auto">
            <v-row style="margin: auto;">		
                <v-col
                    cols="6"
                    lg="3"
                    md="4"
                    v-for="table in binsProcess"
                >
                    <v-badge color="white">
                        <template v-slot:badge>							 
                            <v-btn	
                                style="margin-left: -29px;margin-top: 2px;"
                                fab
                                small
                                color="white"
                                @click="removeBins(table)"
                            >
                                <v-icon 
                                    color="error" 
                                    class="fas fa-trash"
                                    small>
                                </v-icon>
                            </v-btn>								 
                        </template>
                        <v-card>								 
                            <v-card-title class="black--text text-h4">
                                <v-chip color="warning" >{{ 'Bins - '+table.PcbNumber }}</v-chip>
                            </v-card-title>
                            <v-card-text>
                                
                            </v-card-text>
                            <v-card-actions>
                                <v-row justify="space-around" no-gutters>
                                    <v-btn 
                                        color="info"
                                        elevation="2"
                                        fab
                                        x-large                                        
                                        depressed
                                        @click="btnAssignStaticCart(table)">
                                        <v-icon color="white" class="mdi-48px" >mdi-cube</v-icon>
                                    </v-btn>
                                </v-row>
                            
                        </v-card-actions>
                            <v-card-actions>
                                <v-row style="margin-top: 0.1em;">
                                    <v-col cols="12" lg="12" md="12" sm="12" >
                                        <s-select
                                            label="Estacion en Proceso"
                                            :items="tableProcess"
                                            item-text="PwsDescription"
                                            item-value="PwpID"
                                            v-model="table.PwpID"
                                            clearable
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="12" md="12" sm="12" >
                                        <v-btn
                                            outlined
                                            small
                                            elevation="3"
                                            color="success"
                                            style="width:100%"
                                            @click="clickSend(table)"
                                        >
                                            Enviar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>																
                        </v-card>	
                    </v-badge>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog 
            v-model="modalAssembleBins" 
            v-if="modalAssembleBins"
            width="1200"
            persistent
        >
            <packing-assemble-bins
                :PcbID="PcbID"
                @closeAssembleBins="closeAssembleBins()"
            ></packing-assemble-bins>
        </v-dialog>
    </div>
</template>
<script>


   
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    import _sFrzPackingCreateBinsService from "@/services/FrozenProduction/FrzPackingCreateBins.js";
    import packingAssembleBins from './FrzPackingAssembleBins.vue';

    import _sServiceTableProcess from "@/services/FrozenProduction/FrzPackingStationGroupShift.js"

    

    export default {
        components: {packingAssembleBins},
        data(){
            return {
                
                itemTurn:[],
                itemHead: {},
                binsProcess:[],
                itemBins:{},
                modalAssembleBins:false,
                tableProcess:[],
            }
        },
        created(){
            this.TurnActive();
            
        },
        methods:{
            
           
            TurnActive(){
            let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{

                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;   
                        console.log(this.itemTurn[0].TrnID)
                        this.listBinsProcess(this.itemTurn[0].TrnID);     
                        this.listtableProcess(this.itemTurn[0].TrnID);                            
                    }
                });      
            },
            save(){
                console.log('Guardar Nuevo Bins');
               
                if(this.itemHead.TrnID == undefined)
                {
                    this.$fun.alert("Seleccione Turno", "warning");
                    return;
                }
                
                let item = {
                    TrnID : this.itemHead.TrnID,
                    UsrCreateID : this.$fun.getUserID()
                }

                this.$fun.alert("Desea Crear Bins", "question").then(r => {
                    if(r.value)
                    {
                        _sFrzPackingCreateBinsService.save(item, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Se registro correctamente", "success");
                                this.listBinsProcess(this.itemTurn[0].TrnID);  
                            }
                        });
                    }
                })
            },
            listBinsProcess(TrnID){
                _sFrzPackingCreateBinsService.list({TrnID : TrnID}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {         
                        this.binsProcess = r.data;              
                        console.log("Bins Creado->", this.binsProcess);               
                    }
                })
            },
            btnAssignStaticCart(bins){
                console.log('Armar Bins',bins)
                this.itemBins = bins;
                this.PcbID = bins.PcbID
                this.modalAssembleBins = true;
                this.TurnActive();
            },
            closeAssembleBins(){
                this.modalAssembleBins = false
            },
            listtableProcess(TrnID)
            {
                _sServiceTableProcess.list({TrnID : TrnID}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {         
                        this.tableProcess = r.data;              
                        console.log("this.tableProcess->", this.tableProcess);               
                    }
                })
            },
            clickSend(item){
                console.log('item',item);
                if (item.PwpID == null) {
					this.$fun.alert("Seleccione Estacion", "warning");
					return;
				}
                let val = {
                    PwpID : item.PwpID,
                    UsrCreateID : this.$fun.getUserID(),
                    UsrUpdateID : this.$fun.getUserID(),
                    PcbID : item.PcbID
                }
                this.$fun.alert("Desea enviar Bins a estacion", "question").then(r => {
                    if(r.value)
                    {
                        _sFrzPackingCreateBinsService.sendBins(val, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Se registro correctamente", "success");
                                this.listBinsProcess(this.itemTurn[0].TrnID);
                            }
                        });
                    }
                })

            },
            removeBins(item){
                console.log('Remover bins PcbID',item.PcbID);
                let val = {
                    PcbID : item.PcbID,
                    UsrUpdateID : this.$fun.getUserID(),
                }
                this.$fun.alert("Desea eliminar bins", "question").then(r => {
                    if(r.value)
                    {
                        _sFrzPackingCreateBinsService.UpdBins(val, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Se elimino correctamente", "success");
                                this.listBinsProcess(this.itemTurn[0].TrnID);
                            }
                        });
                    }
                })
            }




        }
    }
</script>